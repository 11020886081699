<template>
  <el-tooltip popper-class="tooltip" placement="bottom">
    <el-image class="assets-tips" :src="require('@/assets/tips.png')"></el-image>
    <template slot="content">
      <div class="assets-money-info">
        <div>实际提现：￥{{ accountprice }}</div>
        <div>手续费用：￥{{ parseFloat(applyprice - accountprice).toFixed(2) }}</div>
      </div>
    </template>
  </el-tooltip>
</template>

<script>
export default {
  props: ['accountprice', 'applyprice'],
};
</script>

<style lang="less">
.assets-tips {
  width: 13px;
  height: 11px;
  margin-right: 5px;
}
.assets-money-info {
  color: #333;
  div {
    display: flex;
    align-items: center;
    &:before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #1467ff;
      margin-right: 5px;
    }
    &:nth-child(1) {
      margin-bottom: 8px;
    }
  }
}
.el-tooltip__popper[x-placement^='bottom'] .popper__arrow {
  border-bottom-color: white !important;
}
.el-tooltip__popper[x-placement^='bottom'] .popper__arrow:after {
  border-bottom-color: white !important;
}
.tooltip {
  background: white !important;
  box-shadow: 1px 1px 10px 3px #d3d3d6;
}
</style>